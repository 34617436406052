export const skills = [
  {skill: 'C++ 14'},
  {skill: 'Java 11'},
  {skill: 'Javascript'},
  {skill: 'HTML / CSS'},
  {skill: 'SQL'},
  {skill: 'Agile Scrum'},
  {skill: 'Embedded Systems'},
  {skill: 'Distributed Systems'},
  {skill: 'Electric Power Systems'}
];
